"use client";

import { Volume, Volume2Icon, VolumeX } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { If } from "react-haiku";

export const InlineVideo = ({
  src = "",
  className = "",
  hasSound = false,
  ...props
}) => {
  const observerRef = useRef<HTMLVideoElement>(null);
  const [sound, setSound] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!observerRef.current) return;
          if (entry.isIntersecting) {
            observerRef.current?.play();
          } else {
            observerRef.current?.pause();
            setSound(false);
          }
        });
      },
      { threshold: 0.15 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  if (sound) {
    return (
      <div className="relative h-full w-full">
        <video
          ref={observerRef}
          className={className}
          autoPlay
          playsInline
          src={src}
          {...props}
          onClick={() => {
            if (observerRef.current && hasSound) {
              setSound(!sound);
              if (sound) {
                observerRef.current?.play();
              }
            }
          }}
        />
        <If isTrue={hasSound}>
          <button
            className="absolute bottom-4 right-4 z-10 bg-purple rounded-full p-4"
            onClick={() => {
              if (hasSound) {
                setSound(!sound);
                if (sound) {
                  observerRef.current?.play();
                }
              }
            }}
          >
            <If isTrue={sound}>
              <VolumeX color="white" />
            </If>
            <If isTrue={!sound}>
              <Volume2Icon color="white" />
            </If>
          </button>
        </If>
      </div>
    );
  }

  return (
    <div className="relative h-full w-full">
      <video
        ref={observerRef}
        className={className}
        autoPlay
        loop
        muted
        playsInline
        src={src}
        {...props}
        onClick={() => {
          if (observerRef.current) {
            setSound(!sound);
            if (sound) {
              observerRef.current?.play();
            }
          }
        }}
      />
      <If isTrue={hasSound}>
        <button
          className="absolute bottom-4 right-4 z-10 bg-purple rounded-full p-4"
          onClick={() => {
            setSound(!sound);
            if (sound) {
              observerRef.current?.play();
            }
          }}
        >
          <If isTrue={sound}>
            <VolumeX color="white" />
          </If>
          <If isTrue={!sound}>
            <Volume2Icon color="white" />
          </If>
        </button>
      </If>
    </div>
  );
};
