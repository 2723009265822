"use client";

import { wait } from "@/lib/helpers";
import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";

export default function Video() {
  const video = useRef();
  const text = useRef();

  useEffect(() => {
    if (!video.current) return;
    wait(1000).then(() => {
      if (!video?.current?.playing) {
        return;
      }

      video?.current?.play();
    });
  }, [video, text]);

  return (
    <div className="relative">
      <img
        src="/snoopstein-bigtime.jpg"
        className="w-full object-cover placeholder-img aspect-[9/16] md:aspect-video max-h-[90dvh]"
        alt="Man with pink puffy hair"
      />
      {/* <ReactPlayer
        url="/Snoopstein.mp4"
        onReady={async () => {
          await wait(500);
          console.log("can play");
          text?.current?.classList.add("fade-out");
          await wait(500);
          video.current.play();
        }}
      /> */}
      <video
        ref={video}
        muted
        playsInline
        autoPlay
        preload="auto"
        className="w-full object-cover h-full absolute left-0 top-0"
        onPlay={async (e) => {
          if (video?.current?.playing) return;
          // console.log("play", "text", text?.current);
          await wait(2000);
          text?.current?.classList.add("fade-out");
          await wait(1000);
          video.current.play();
        }}
        onLoad={async (e) => {
          // console.log("loaded");
        }}
        onCanPlay={async (e) => {
          // console.log("can play");
          //   await wait(500);
          //   text?.current?.classList.add("fade-out");
          //   await wait(500);
          //   video.current.play();
        }}
      >
        <source
          src="/videos/snoopstein_mobile.mp4"
          type="video/mp4"
          media="all and (max-width: 767px)"
        />
        <source
          src="/videos/brain_hate_web.mp4"
          type="video/mp4"
          media="all and (min-width: 768px)"
        />
      </video>
      {/* <div
        ref={text}
        className="intro-video-text font-black text-white leading-[0.8] font-display max-w-full w-[500px] absolute left-8 top-0 h-full hidden lg:grid place-content-center intro-text drop-shadow-2xl"
      >
        Embrace your stupid ideas, because they're probably smarter than you
        think!
      </div> */}
    </div>
  );
}
